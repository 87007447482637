.container {
    display: flex;
    flex-direction: column; /* Stack inner divs vertically */
    align-items: center;
    padding: 1px;
}

.form-group {
    display: flex;
    flex-direction: row; /* Stack components horizontally inside each form-group */
    align-items: center; /* Align items vertically within the form-group */
    justify-content: center;
    margin: 10px 10px;
    width: 100%; /* Allow the form-group to take the full width of its parent */
    max-width: 600px; /* Optional: Set a maximum width for better readability */
}

.success-message {
    color: green;
    font-weight: bold;
}

.input-label {
    margin-right: 10px;
}
